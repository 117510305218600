@import "../../styles/variables.scss";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;

  width: 256px;
  height: 320px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 2px 2px 16px -4px $black;
  background-color: $background-main;
  background-image: url("../../assets/grit-alpha.png");

  -webkit-transition: box-shadow 200ms ease-out;
  -ms-transition: box-shadow 200ms ease-out;
  transition: box-shadow 200ms ease-out;

  -webkit-transition: top 200ms ease-out;
  -ms-transition: top 200ms ease-out;
  transition: top 200ms ease-out;

  position: relative;
  top: 0;

  &:hover {
    cursor: pointer;
    box-shadow: 4px 4px 32px -8px $black;
    top: -4px;
  }

  img {
    border-radius: 16px;
    border: 2px solid $text-main;
  }
}

.title {
  font-family: $font-main;
  font-size: 32px;
  color: $text-main;
  font-weight: 800;
  text-transform: uppercase;
}

.description {
  font-family: $font-main;
  font-size: 16px;
  color: $text-secondary;
  font-weight: 300;
}
