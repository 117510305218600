@import "../../styles/variables.scss";

.footer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc($footer-height - $border-width);
  background-color: $background-main;
  background-image: url("../../assets/grit-alpha.png");
  box-shadow: 0px 0px 20px -8px $black;

  font-family: $font-main;
  font-size: 18px;
  color: $text-main;
  font-weight: 300;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 4px;
}

.text {
  display: flex;
  gap: 24px;
}
