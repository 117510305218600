@import "../../styles/variables.scss";

.app {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;

  background-color: $background-main;
  // -webkit-mask-image: url("../../assets/grit.png");
  // mask-image: url("../../assets/grit.png");
}
