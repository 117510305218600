@import "../../styles/variables.scss";

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: calc($header-height - $border-width);
  background-image: url("../../assets/grit-alpha.png");
  background-color: $background-main;
  box-shadow: 0px 0px 20px -8px $black;

  font-family: $font-main;
  font-size: 18px;
  color: $text-main;
  font-weight: 300;
  position: sticky;
  top: 0;
  z-index: 99;
}

.pageLink {
  display: flex;
  align-items: center;
  margin: 24px;

  -webkit-transition: color 200ms ease-out;
  -ms-transition: color 200ms ease-out;
  transition: color 200ms ease-out;

  &:hover {
    cursor: pointer;
    color: $text-secondary-highlight;
  }

  @media screen and (max-width: 800px) {
    font-size: 12px;
    margin: 8px;
  }
}

.buttonGroup {
  padding: 0 24px;
  display: flex;

  @media screen and (max-width: 800px) {
    padding: 8px;
  }
}

.button {
  border: 2px solid $text-main;
  border-radius: 8px;

  display: flex;
  align-items: center;
  margin: 16px 8px;
  padding: 8px 16px;

  -webkit-transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  transition: background-color 300ms;
  -webkit-transition: color 300ms;
  -ms-transition: color 300ms;
  transition: color 300ms;

  &:hover {
    cursor: pointer;
    background-color: $text-main;
    color: $background-main;
  }

  @media screen and (max-width: 800px) {
    font-size: 12px;
    margin: 8px;
    padding: 8px;
  }
}
