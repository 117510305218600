@import "../../../styles/variables.scss";

.pogoRogue {
  min-height: calc(100vh - $header-height - $footer-height);
  color: $black;
  background-image: url("../../../assets/grit-alpha.png");
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  padding: 64px 10vw;

  animation: fadeIn ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @media (max-width: 800px) {
    padding: 32px;
  }
}

.videoTile {
  width: 64vw;
  height: 36vw;

  @media (max-width: 800px) {
    width: 90vw;
    height: 54vw;
  }
}

.steamTile {
  width: 64vw;
  max-width: 1024px;
  height: 192px;

  @media (max-width: 800px) {
    width: 90vw;
  }
}

.title {
  text-align: center;

  font-family: $font-main;
  font-size: 48px;
  color: $text-main;
  font-weight: 800;
  text-transform: uppercase;

  @media (max-width: 1300px) {
    font-size: 44px;
  }

  @media (max-width: 800px) {
    font-size: 40px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 24px;

  font-family: $font-main;
  font-size: 32px;
  color: $text-secondary;
  font-weight: 300;

  @media (max-width: 1300px) {
    font-size: 28px;
  }

  @media (max-width: 800px) {
    width: 90vw;
    font-size: 24px;
    text-align: center;
    max-width: 100vw;
    flex-direction: column;
    align-items: center;
  }
}

.longText {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 24px;

  font-family: $font-main;
  font-size: 24px;
  color: $text-secondary;
  font-weight: 300;

  @media (max-width: 800px) {
    width: 90vw;
    font-size: 20px;
    text-align: center;
    max-width: 100vw;
    flex-direction: column;
    align-items: center;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
