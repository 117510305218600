@import "../../styles/variables.scss";

.home {
  min-height: calc(100vh - $header-height - $footer-height);
  background-image: url("../../assets/grit-alpha.png");
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 384px;

  padding: 256px 10vw;

  animation: fadeIn ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @media (max-width: 800px) {
    padding: 32px;
    gap: 128px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 24px;

  max-width: 50vw;

  font-family: $font-main;
  font-size: 32px;
  color: $text-secondary;
  font-weight: 300;

  @media (max-width: 800px) {
    text-align: center;
    max-width: 100vw;
    font-size: 28px;
  }

  .paragraph {
    font-size: 24px;
  }
}

.largeText {
  font-family: $font-main;
  font-size: 64px;
  color: $text-main;
  font-weight: 800;

  @media (max-width: 800px) {
    font-size: 48px;
  }
}

.title {
  text-align: center;
  padding-right: 16px;

  font-family: $font-main;
  font-size: 64px;
  color: $text-main;
  font-weight: 800;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;
  }
}

.headshot {
  object-fit: contain;
  width: 35%;
  padding-left: 16px;

  @media (max-width: 800px) {
    padding-left: 0px;
    width: 90%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
