@import "../../styles/variables.scss";

.resume {
  min-height: calc(100vh - $header-height - $footer-height);
  color: $black;
  background-image: url("../../assets/grit-alpha.png");

  font-family: $font-main;
  font-size: 32px;
  color: $text-secondary;
  font-weight: 300;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  padding: 64px 10vw;

  animation: fadeIn ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @media (max-width: 800px) {
    padding: 32px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 24px;

  @media (max-width: 800px) {
    width: 90vw;
    font-size: 24px;
    text-align: center;
    max-width: 100vw;
    flex-direction: column;
    align-items: center;
  }
}

.text {
  font-family: $font-main;
  font-size: 32px;
  color: $text-secondary;
  font-weight: 300;

  @media (max-width: 1300px) {
    font-size: 28px;
  }

  @media (max-width: 800px) {
    font-size: 24px;
    text-align: center;
  }
}

.title {
  text-align: center;
  padding-bottom: 32px;

  font-family: $font-main;
  font-size: 48px;
  color: $text-main;
  font-weight: 800;
  text-transform: uppercase;

  @media (max-width: 1300px) {
    font-size: 44px;
  }

  @media (max-width: 800px) {
    font-size: 40px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .text {
    @media (max-width: 1300px) {
      flex-direction: column;
    }
  }
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;
  }
}

.link {
  display: flex;
  align-items: center;

  -webkit-transition: color 200ms ease-out;
  -ms-transition: color 200ms ease-out;
  transition: color 200ms ease-out;

  &:hover {
    cursor: pointer;
    color: $text-secondary-highlight;
  }
}

.button {
  font-family: $font-main;
  font-size: 18px;
  color: $text-main;
  font-weight: 300;

  border: 2px solid $text-main;
  border-radius: 8px;

  display: flex;
  align-items: center;
  margin: 16px 8px;
  padding: 8px 16px;

  -webkit-transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  transition: background-color 300ms;
  -webkit-transition: color 300ms;
  -ms-transition: color 300ms;
  transition: color 300ms;

  &:hover {
    cursor: pointer;
    background-color: $text-main;
    color: $background-main;
  }
}

.skills {
  display: flex;
  flex-direction: column;
  gap: 8px;

  -webkit-mask-image: url("../../assets/grit.png");
  mask-image: url("../../assets/grit.png");

  .skill {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    .label {
      width: 360px;
      padding-right: 32px;
      text-align: right;

      font-family: $font-main;
      font-size: 24px;
      color: $text-secondary;
      font-weight: 300;

      @media (max-width: 800px) {
        text-align: center;
        padding: 0px;
      }
    }

    .progress {
      display: flex;
      align-items: center;
      gap: 2px;

      .progressLeft {
        width: 48px;
        height: 16px;
        background-color: $accent;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border: 2px solid $text-main;

        @media (max-width: 800px) {
          width: 24px;
        }
      }

      .progressFull {
        width: 48px;
        height: 16px;
        background-color: $accent;
        border: 2px solid $text-main;

        @media (max-width: 800px) {
          width: 24px;
        }
      }

      .progressEmpty {
        width: 48px;
        height: 16px;
        border: 2px solid $text-main;

        @media (max-width: 800px) {
          width: 24px;
        }
      }

      .progressRight {
        width: 48px;
        height: 16px;
        background: transparent;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 2px solid $text-main;

        @media (max-width: 800px) {
          width: 24px;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
